<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>


        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Whitelabels') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->

            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">

              <b-button  variant="primary" @click="onWhitelabelSelect(0)">
                  <span class="text-nowrap">{{ msg('New whitelabel') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="bank-table"
            striped
            hover
            responsive
            v-bind:items="whitelabels"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >

          <template #cell(buid)="data">
            <b-badge variant="primary">{{ getBranchName(data.value) }}</b-badge>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>


        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.whitelabel" size="xl" ref="whitelabel-modal" id="whitelabel-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <whitelabel-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitWhitelabel">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import WhitelabelPanel from "@/views/whitelabel/whitelabelPanel";

export default {
  name: 'Whitelabels',
  components: {
WhitelabelPanel

  },
  data() {
    return {
      buid: null,

      loading: true,
      amount: 10,
      whitelabels: [],
      fields: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", whitelabel: true},
        lte: {},
        gte: {},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {whitelabel: false},
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getBranchName']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },

    allwhitelabels() {
      return this.whitelabels
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('whitelabel', ['getAllWhitelabel']),


    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [

        {
          key: 'buid',
          label: $this.msg('Branch'),
          sortable: true
        },

      ]
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllWhitelabel({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onwhitelabelsRetrieve, (error) => $this.error = error)

    },
    onwhitelabelsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.whitelabels = data["Whitelabels"];
      this.loading = false;
    },
    onWhitelabelSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.whitelabel = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(whitelabel) {
      this.$router.push(`/whitelabel/edit/${whitelabel.id}`);
    },
    submitWhitelabel() {
      this.$root.$emit('crm::submitWhitelabel')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.whitelabel = false;
    }
  }
}
</script>

<style>

</style>
